import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/vis',
  //   name: 'vis',
  //   component: () => import('./views/scratch/vue-vis-network-test.vue'),
  //   props: true,
  // },

  {
    path: '/scratch',
    name: 'Scratch',
    component: () => import('../srv/tests/scratch.vue'),
    props: true,
  },

  // {
  //   path: '/test/:nonce/:eventName',
  //   name: 'Test',
  //   component: () => import('./components/frames/campaignEvent.vue'),
  //   props: true,
  // },

  {
    path: '/campaign',
    name: 'Campaign',
    component: () => import('./views/Campaign.vue'),
    meta: { connectionType: 'Campaign' }, // used by Campaign.vue to link to Model.vue for data
    props: true,
  },
  {
    path: '/campaigns',
    redirect: '/campaign',
  },

  {
    path: '/reward',
    name: 'Reward',
    component: () => import('./views/Connections.vue'),
    meta: { connectionType: 'Reward' },
    props: true,
  },
  {
    path: '/rewards',
    redirect: '/reward',
  },
  {
    path: '/theme',
    name: 'Theme',
    component: () => import('./views/Theme.vue'),
  },
  // {
  //   path: '/contact',
  //   name: 'Contact',
  //   component: () => import('./views/Contact.vue'),
  //   meta: { connectionType: 'Contact' },
  //   props: true,
  // },

  // {
  //   path: '/contacts',
  //   redirect: '/contact',
  // },

  {
    path: '/view/events/:nonce/:eventName/:earn',
    name: 'Event.nonce',
    component: () => import('@/views/View.vue'),
    meta: { connectionType: 'Campaign' },
    props: true,
  },
  {
    path: '/view/events/:nonce/:eventName',
    name: 'Event.nonce.eventName',
    component: () => import('@/views/View.vue'),
    meta: { connectionType: 'Campaign' },
    props: true,
  },
  //http://localhost:3333
  //       /view/events/chrome@campUrl?country=lo&url=https%3A%2F%2Ftqrtoken.com%2Fcampaign-earn
  {
    path: '/view/events/:nonce/',
    name: 'Campaign URL',
    component: () => import('@/views/View.vue'),
    meta: { connectionType: 'Campaign' },
    props: true,
  },

  {
    path: '/view/rewards/:nonce/:earn',
    name: 'View.rewards.nonce.earn',
    component: () => import('@/views/View.vue'),
    meta: { connectionType: 'Reward' },
    props: true,
  },
  {
    path: '/view/rewards/:nonce',
    name: 'View.rewards.nonce',
    component: () => import('@/views/View.vue'),
    meta: { connectionType: 'Reward' },
    props: true,
  },
  // {
  //   path: '/view/contacts/',
  //   name: 'View.contacts.contact',
  //   component: () => import('@/views/ViewContact.vue'),
  //   meta: { connectionType: 'Contact' },
  //   props: true,
  // },
  // {
  //   path: '/view/contacts/:pk/:contact',
  //   name: 'View.contacts.pk',
  //   component: () => import('@/views/ViewContact.vue'),
  //   meta: { connectionType: 'Contact' },
  //   props: true,
  // },

  //#endregion

  {
    path: '/qrPrintCard/:owner/:nonce',
    name: 'qrPrintCard',
    component: () => import('@/components/qrPrintCard.vue'),
    props: true,
  },

  {
    path: '/consumer',
    name: 'Consumer',
    component: () => import('@/views/Consumer2.vue'),
    props: true,
  },
  {
    path: '/consumer/:nonce',
    name: 'Consumer.nonce',
    component: () => import('@/views/Consumer2.vue'),
    props: true,
  },

  /*{
    path: '/oldhome',
    name: 'OldHome',
    component: () => import('@/views/Home.vue'),
    props: true,
  },
  {
    path: '/new',
    name: 'Home.newPK',
    component: () => import('@/views/Home2.vue'),
    props: true,
  },*/
  // /:pk may include a query.pin value
  {
    path: '/:pk',
    name: 'Home.setPK',
    component: () => import('@/views/Home.vue'),
    props: true,
  },
  /*
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home2.vue'),
    props: true,
  },

  // TODO pri2/sev2: has this ever worked? have we gotten down the router array this far without being stopped at /:pk
  {
    path: '/:indexedComponent',
    name: 'Home.Indexed',
    component: () => import('@/views/Home2.vue'),
    props: true,
  },*/

  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    props: true,
  },

  {
    path: '/:catchAll(.*)',
    name: 'ErrorPage',
    component: () => import('@/views/ErrorPage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const hostname = location.hostname;
  const pathname = location.pathname;

  if (pathname === '' || pathname === '/' || to.path === '/') {
    switch (hostname) {
      case 'sg.tqrtoken.net':
      case 'uk.tqrtoken.net':
      case 'us.tqrtoken.net':
      case 'zz.tqrtoken.net':
        window.location.href = 'https://tqrtoken.com';
        next(false);
        break;
      default:
        next();
    }
  } else {
    next();
  }
});

export default router;
